<template>
  <div class="d-flex text-h6" :class="{ 'flex-column': $msdcrm }">
    <div
      class="text-uppercase flex-shrink-0"
      :class="{ 'text-caption': $msdcrm }"
    >
      {{ title }}
    </div>
    <div v-if="!$msdcrm" class="font-weight-regular mx-1">-</div>
    <div v-if="currentDupeSet.name" class="text-truncate font-weight-regular">
      {{ currentDupeSet.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    currentDupeSet: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
