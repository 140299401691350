<template>
  <v-app>
    <DevMergeHistoryAlertInfo v-if="isSPA" class="ma-4" />

    <v-sheet
      v-if="dupeSet && !isLoading && !error"
      color="#eff2f5"
      class="flex-grow-1 pa-3"
    >
      <DupesGridDialogTitle
        title="Record Audit Trail"
        :current-dupe-set="dupeSet"
        class="mb-2"
      />

      <v-card outlined>
        <DupesGridIframe
          v-if="dupeSet.id"
          mode="history"
          :dupe-set="iframeDupeSet"
        />
      </v-card>

      <DupesGridDialogFooterDetails
        :items="footerDetails"
        class="d-flex mt-3"
      />
    </v-sheet>

    <div v-if="isLoading" class="d-flex align-center justify-center h-full">
      <v-progress-circular indeterminate />
    </div>

    <div
      v-if="(!isLoading && !dupeSet) || error"
      class="d-flex align-center justify-center h-full"
    >
      <v-alert color="warning" icon="$mdi-alert-circle-outline">
        {{ error }}
      </v-alert>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DupesGridDialogTitle from '@/components/dupesgrid/DupesGridDialogTitle'
import DupesGridDialogFooterDetails from '@/components/dupesgrid/DupesGridDialogFooterDetails'
import DupesGridIframe from '@/components/dupesgrid/DupesGridIframe'
import {
  getDupeSetByRecord,
  getDupeSetHistory,
} from '@/api/dupe-sets/dupe-sets'
import { isSPA } from '@/utils/env'

export default {
  components: {
    DupesGridIframe,
    DupesGridDialogTitle,
    DupesGridDialogFooterDetails,
    DevMergeHistoryAlertInfo: () =>
      import('@/components/dev/DevMergeHistoryAlertInfo.vue'),
  },
  data() {
    return {
      isSPA,
      dupeSet: {},
      dupeSetHistory: {},
      isLoading: true,
      iframeDupeSet: {},
      error: '',
    }
  },
  computed: {
    ...mapState('auth', ['agentId']),
    footerDetails() {
      const items = []

      if (this.dupeSet.number) {
        items.push({
          label: 'Set ID:',
          value: this.dupeSet.number,
        })
      }

      if (this.dupeSetHistory.ownerName) {
        items.push({
          label: 'Owner:',
          value: this.dupeSetHistory.ownerName,
        })
      }

      if (this.dupeSetHistory.createdAtDate) {
        items.push({
          label: 'Created:',
          value: this.dupeSetHistory.createdAtDate,
        })
      }

      if (this.dupeSetHistory.statusChangedAtDate) {
        items.push({
          label: 'Merged:',
          value: this.dupeSetHistory.statusChangedAtDate,
        })
      }

      if (this.dupeSetHistory.statusChangedBy) {
        items.push({
          label: 'Merged by:',
          value: this.dupeSetHistory.statusChangedBy,
        })
      }

      return items
    },
  },
  async created() {
    const { entity_key: entityKey, entity_name: entityName } = this.$route.query

    if (entityKey && entityName) {
      this.iframeDupeSet = {
        primaryId: entityKey,
        source: entityName,
      }

      await this.getDupeSet({ entityKey, entityName })
    } else {
      this.error = 'Malformed URL. Please verify query params.'
    }
  },
  methods: {
    ...mapActions('app', ['setCurrentError']),
    async getDupeSet({ entityKey, entityName }) {
      this.isLoading = true

      try {
        this.dupeSet = await getDupeSetByRecord(this.agentId, {
          entityKey,
          entityName,
        })

        if (this.dupeSet.isStateMerged) {
          if (!this.dupeSet.id)
            throw new Error('Duplicate set id not present in the response.')

          this.dupeSetHistory = await getDupeSetHistory(this.dupeSet.id)
        } else {
          this.error = 'This record has not yet been merged.'
        }
      } catch (error) {
        this.setCurrentError({
          message: error,
          details: 'AP: GetDupeSet_MergedHistory',
        })

        this.error = 'Record history not found.'
        console.error('AP:GetDupeSet_MergedHistory', error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
