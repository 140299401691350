<template>
  <div>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="mr-4 d-flex"
      :class="{ 'flex-column': columnDisplay }"
    >
      <div class="font-weight-black">
        {{ item.label }}
      </div>
      <div :class="{ 'ml-1': !columnDisplay }">
        {{ item.value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columnDisplay: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
