<template>
  <div class="dupes-grid-iframe">
    <iframe
      ref="iframe"
      frameborder="0"
      scrolling="no"
      :src="dupesGridUrl"
      :style="iFrameStyle"
      @load="onIFrameLoad"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { merge } from 'lodash-es'
import { sendBusMessage } from '@/services/message-bus'

export default {
  props: {
    dupeSet: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: 'duplicate', // 'duplicate', 'excluded', 'history'
    },
  },
  data() {
    return {
      iFrameOrigin: '*',
      iFrameStyle: {
        height: '100px',
      },
    }
  },
  computed: {
    ...mapState('auth', ['agentId', 'contextMessage']),
    ...mapState('app', ['dupesGridUrl']),
  },
  mounted() {
    window.addEventListener('message', this.onMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessage)
  },
  methods: {
    onMessage(event) {
      if (
        event.data &&
        typeof event.data !== 'object' &&
        event.source !== window
      ) {
        let payload

        try {
          payload = JSON.parse(event.data)

          switch (payload.action) {
            case 'openLink':
              window.open(payload.url, '_blank')
              break
            case 'resizeFrame':
              this.iFrameStyle = {
                height: `${payload.height}px`,
              }

              sendBusMessage({
                action: 'resizeWindow',
                source: 'MergeHistory',
                height: document.body.offsetHeight,
              })

              break
          }
        } catch (error) {
          console.warn('[AP] InvalidEventMessageError', error)
        }
      }
    },
    onIFrameLoad() {
      this.sendInitMessage()
    },
    sendInitMessage() {
      if (this.dupeSet) {
        const dupeSetId = this.mode === 'history' ? this.dupeSet.id : undefined

        const baseMessage = {
          dupeSetId,
          env: localStorage.getItem('env'),
          agent_id: this.agentId,
          mode: this.mode,
          test_jwt: 'admin',
          data: {
            entity_key: this.dupeSet.primaryId,
            entity_name: this.dupeSet.source,
          },
        }

        const message = this.contextMessage
          ? merge({}, this.contextMessage, {
              dupeSetId,
              data: baseMessage.data,
              mode: baseMessage.mode,
            })
          : baseMessage

        this.$refs.iframe.contentWindow.postMessage(message, this.iFrameOrigin)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dupes-grid-iframe {
  min-height: 254px;
  margin-bottom: -8px;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    transition: height 0.2s;
  }
}
</style>
